import React, { createContext, FC, useContext } from "react";

import { useLoginState } from "~/components/Login/hooks/useLoginState/useLoginState";
import { emptyCallback } from "~/util/emptyCallback";

export type LoginStateContextType = {
  orderId: string;
  setOrderId: (orderId: string) => void;
  customerEmail: string;
  setCustomerEmail: (customerEmail: string) => void;
  resetLoginState: () => void;
  isDisableCTA: boolean;
};

export const LoginStateContext = createContext<LoginStateContextType>({
  orderId: "",
  setOrderId: emptyCallback,
  customerEmail: "",
  setCustomerEmail: emptyCallback,
  resetLoginState: emptyCallback,
  isDisableCTA: false,
});

export const LoginStateContextProvider: FC = ({ children }) => {
  const value = useLoginState();
  return (
    <LoginStateContext.Provider value={value}>
      {children}
    </LoginStateContext.Provider>
  );
};

export const useLoginStateContext = () => useContext(LoginStateContext);
