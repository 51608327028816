import { sha256 } from "js-sha256";

import {
  ConfirmReturnVariables,
  CreateShipmentVariables,
  FetchOrderRegisteredItemsVariables,
  FetchReturnMethodsNotesVariables,
  FetchOrderVariables,
  FetchProductDetailsVariables,
  FetchReturnMethodsVariables,
  CreatePayPalOrderVariables,
  ConfirmReturnPaymentVariables,
} from "~/common/types/api";
import {
  AuthorizePayPalOrderResponse,
  CreatePayPalOrderResponse,
} from "~/common/types/paypal";
import { ProductRaw } from "~/common/types/product";
import { CollectionDetailsRaw, CollectionRaw } from "~/common/types/collection";
import { ConfirmReturnResponseRaw } from "~/hooks/useConfirmReturn/useConfirmReturn.types";
import { CookiesSettingsRaw } from "~/hooks/useCookiesSettings/useCookiesSettings.types";
import { CreateShipmentResponseRaw } from "~/hooks/useCreateShipment/useCreateShipment.types";
import { DHLDownStatusResponseRaw } from "~/hooks/useDHLDownStatus/useDHLDownStatus.types";
import { MerchantSettingsResponseRaw } from "~/hooks/useMerchantSettings/useMerchantSettings.types";
import { OrderDetailsResponseRaw } from "~/hooks/useOrderDetails/useOrderDetails.types";
import { OrderRegisteredItemsResponseRaw } from "~/hooks/useOrderRegisteredItems/useOrderRegisteredItems.types";
import { ReturnMethodsResponseRaw } from "~/hooks/useReturnMethods/useReturnMethods.types";
import { ReturnMethodsNotesResponseRaw } from "~/hooks/useReturnMethodsNotes/useReturnMethodsNotes.types";
import { TradeInItemsResponseRaw } from "~/hooks/useTradeInItems/useTradeInItems.types";
import {
  getConfirmReturnPaymentVariablesFromPaymentAuthorizationResponse,
  getConfirmReturnRequestPayload,
  getCreatePayPalOrderRequestPayload,
  getCreateShipmentRequestPayload,
  getFetchOrderQueryString,
  getFetchOrderRegisteredItemsQueryString,
  getFetchProductDetailsQueryString,
  getFetchReturnMethodsNotesQueryString,
  getFetchReturnMethodsRequestPayload,
} from "~/util/api";
import { getXCSRFToken } from "~/util/getXCSRFToken";

export const fetchMerchantSettings = () => {
  return fetch("/settings").then<MerchantSettingsResponseRaw>((response) =>
    response.json()
  );
};

export const fetchCookiesSettings = () => {
  return fetch("/settings/cookie_enabled").then<CookiesSettingsRaw>(
    (response) => {
      return response.json();
    }
  );
};

export const fetchOrder = (params: FetchOrderVariables) => {
  const queryString = getFetchOrderQueryString(params);
  return fetch(`/orders.json?${queryString}`)
    .then<OrderDetailsResponseRaw>((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    });
};

export const fetchOrderRegisteredItems = (
  params: FetchOrderRegisteredItemsVariables
) => {
  const queryString = getFetchOrderRegisteredItemsQueryString(params);
  return fetch(
    `/orders/registered_items.json?${queryString}`
  ).then<OrderRegisteredItemsResponseRaw>((response) => response.json());
};

export const fetchReturnMethods = (params: FetchReturnMethodsVariables) => {
  const requestPayload = getFetchReturnMethodsRequestPayload(params);
  return fetch(`/rules`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(requestPayload),
  }).then<ReturnMethodsResponseRaw>((response) => response.json());
};

export const createShipment = (params: CreateShipmentVariables) => {
  const requestPayload = getCreateShipmentRequestPayload(params);
  return fetch("/shipments", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(requestPayload),
  })
    .then<CreateShipmentResponseRaw>((response) => {
      if (response.status === 500) {
        throw new Error(
          "Failed to create return label. Please recheck your address and try again or contact customer support"
        );
      }
      return response.json();
    })
    .then((data) => {
      if (data.error?.length) {
        throw new Error(data.error[0]);
      }
      return data;
    });
};

export const confirmReturn = (params: ConfirmReturnVariables) => {
  const requestPayload = getConfirmReturnRequestPayload(params);

  const checksum = sha256.hmac(getXCSRFToken(), JSON.stringify(requestPayload));
  return fetch("/refunds", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
      "X-Checksum": checksum,
    },
    body: JSON.stringify(requestPayload),
  })
    .then<ConfirmReturnResponseRaw>((response) => {
      if (response.status >= 500) {
        throw new Error(
          "Failed to confirm return. Please try again or contact customer support"
        );
      }
      return response.json();
    })
    .then((data) => {
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    });
};

export const fetchReturnMethodsNotes = (
  params: FetchReturnMethodsNotesVariables
) => {
  const queryString = getFetchReturnMethodsNotesQueryString(params);
  return fetch(
    `/merchant_return_methods/${params.merchantReturnMethodId}/notes?${queryString}`
  ).then<ReturnMethodsNotesResponseRaw>((response) => response.json());
};

export const fetchProductDetails = ({
  productId,
  orderId,
  exchangedOrderItemId,
  useShopProductEndpoint,
}: FetchProductDetailsVariables) => {
  if (useShopProductEndpoint) {
    return fetch(`/shop_now/products/${productId}.json`).then<ProductRaw>(
      (response) => response.json()
    );
  }
  const queryString = getFetchProductDetailsQueryString({
    orderId,
    exchangedOrderItemId,
  });
  return fetch(`/products/${productId}${queryString}`).then<ProductRaw>(
    (response) => response.json()
  );
};

export const fetchDHLDownStatus = () =>
  fetch("/settings/dhl_not_working").then<DHLDownStatusResponseRaw>(
    (response) => response.json()
  );

export const fetchTradeInItems = (orderId: string) =>
  fetch(`/trade_in_items?order_id=${orderId}`).then<TradeInItemsResponseRaw>(
    (response) => response.json()
  );

export const createPayPalOrder = (params: CreatePayPalOrderVariables) => {
  const requestPayload = getCreatePayPalOrderRequestPayload(params);
  return fetch("/payment/orders", {
    method: "POST",
    body: JSON.stringify(requestPayload),
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
  })
    .then<CreatePayPalOrderResponse>((response) => response.json())
    .then<string>((order) => order.id);
};

export const authorizePayPalOrder = (orderId: string) =>
  fetch(`/payment/orders/${orderId}/authorize`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
  })
    .then<AuthorizePayPalOrderResponse>((response) => response.json())
    .then<ConfirmReturnPaymentVariables>((data) =>
      getConfirmReturnPaymentVariablesFromPaymentAuthorizationResponse(data)
    );

export const fetchCollections = () => {
  return fetch(`/shop_now/collections?product_limit=4`).then<CollectionRaw[]>(
    (response) => response.json()
  );
};

export const fetchCollection = (
  collectionId: string,
  nextPage?: string,
  prevPage?: string
) => {
  let queryParam = "";
  if (nextPage) {
    queryParam = `next_page=${nextPage}`;
  }
  if (prevPage) {
    queryParam = `prev_page=${prevPage}`;
  }
  return fetch(
    `/shop_now/collections/${collectionId}?${queryParam}&product_limit=12`
  ).then<CollectionDetailsRaw>((response) => response.json());
};
