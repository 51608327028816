import { lazy } from "react";

import { RouteType } from "~/common/types/common";
import { importWithRetry } from "~/util/importWithRetry";

/* To disable code splitting / lazy loading of components 
   Just replace the dynamic lazy imports with the static imports (commented out)
*/
// import Login from "~/components/Login/index";
// import SelectItems from "~/components/SelectItems/index";
// import ReturnType from "~/components/ReturnType/index";
// import ExchangeVariant from "~/components/ExchangeVariant/index";
// import CustomReturn from "~/components/CustomReturn/index";
// import SelectQuantity from "~/components/SelectQuantity/index";
// import ReturnReasons from "~/components/ReturnReasons/index";
// import ReturnMethods from "~/components/ReturnMethods/index";
// import MoreDetails from "~/components/MoreDetails/index";
// import ReturnOverview from "~/components/ReturnOverview/index";
// import RefundInfo from "~/components/RefundInfo/index";
// import RefundType from "~/components/RefundType/index";
// import ReturnConfirmation from "~/components/ReturnConfirmation/index";
// import Error from "~/components/Error/Error";
// import Payment from "~/components/Payment/Payment";
// import CollectionsList from "~/components/CollectionsList/index";
// import CollectionDetails from "~/components/CollectionDetails/index";
// import ProductDetails from "~/components/ProductDetails/index";
import { Path } from "./path";

const authRoutes: RouteType[] = [
  {
    path: Path.Login,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/Login/index"))
    ),
    // Component: Login,
    exact: true,
  },
];

const authenticatedRoutes: RouteType[] = [
  {
    path: Path.SelectItems,
    // Component: lazy(() =>
    //   importWithRetry(() => import("../../components/CollectionsList/index"))
    // ),
    // Component: lazy(() =>
    //   importWithRetry(() => import("../../components/CollectionDetails/index"))
    // ),
    // Component: lazy(() =>
    //   importWithRetry(() => import("../../components/ProductDetails/index"))
    // ),
    Component: lazy(() =>
      importWithRetry(() => import("../../components/SelectItems/index"))
    ),
    // Component: SelectItems,
    exact: true,
  },
  {
    path: Path.ReturnType,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/ReturnType/index"))
    ),
    // Component: ReturnType,
    exact: true,
  },
  {
    path: Path.ExchangeVariant,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/ExchangeVariant/index"))
    ),
    // Component: ExchangeVariant,
    exact: true,
  },
  {
    path: Path.CustomReturn,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/CustomReturn/index"))
    ),
    // Component: CustomReturn,
    exact: true,
  },
  {
    path: Path.SelectQuantity,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/SelectQuantity/index"))
    ),
    // Component: SelectQuantity,
    exact: true,
  },
  {
    path: Path.ReturnReason,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/ReturnReasons/index"))
    ),
    // Component: ReturnReasons,
    exact: true,
  },
  {
    path: Path.ReturnMethod,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/ReturnMethods/index"))
    ),
    // Component: ReturnMethods,
    exact: true,
  },
  {
    path: Path.MoreDetails,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/MoreDetails/index"))
    ),
    // Component: MoreDetails,
    exact: true,
  },
  {
    path: Path.ReturnOverview,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/ReturnOverview/index"))
    ),
    // Component: ReturnOverview,
    exact: true,
  },
  // {
  //   path: Path.RefundInfo,
  //   Component: lazy(() =>
  //     importWithRetry(() => import("../../components/RefundInfo/index"))
  //   ),
  //   // Component: RefundInfo,
  //   exact: true,
  // },
  {
    path: Path.RefundType,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/RefundType/index"))
    ),
    // Component: RefundType,
    exact: true,
  },
  {
    path: Path.ReturnConfirmation,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/ReturnConfirmation/index"))
    ),
    // Component: ReturnConfirmation,
    exact: true,
  },
  {
    path: Path.Payment,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/Payment/index"))
    ),
    // Component: Payment,
    exact: true,
  },
  {
    path: Path.CollectionsList,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/CollectionsList/index"))
    ),
    // Component: CollectionsList,
    exact: true,
  },
  {
    path: Path.CollectionDetails,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/CollectionDetails/index"))
    ),
    // Component: CollectionDetails,
    exact: true,
  },
  {
    path: Path.ProductDetailsShop,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/ProductDetailsShop/index"))
    ),
    // Component: ProductDetails,
    exact: true,
  },
];

const errorRoute: RouteType[] = [
  {
    path: Path.Error,
    Component: lazy(() =>
      importWithRetry(() => import("../../components/Error/Error"))
    ),
    // Component: Error,
    exact: true,
  },
];

const publicRoutes: RouteType[] = [];

export const appRoutes: RouteType[] = [
  ...publicRoutes,
  ...authRoutes,
  ...authenticatedRoutes,
  ...errorRoute,
];
