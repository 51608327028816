import { curry } from "lodash";

import { LocaleCodes } from "~/common/types/common";

let locale: LocaleCodes = "en";

export enum NumberFormat {
  Currency = "currency",
}

const numberFormatterMap = {
  [NumberFormat.Currency]: (currency: string) =>
    new Intl.NumberFormat(locale, {
      style: "currency",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency,
    }).format,
};

const getNumberFormatter = (format: NumberFormat) => numberFormatterMap[format];

const formatNumber = (
  format: NumberFormat,
  currency: string,
  value: number | bigint
): string => getNumberFormatter(format)(currency)(value);

const formatNumberCurried = curry(formatNumber);

export const formatCurrency = formatNumberCurried(NumberFormat.Currency);

export function setFormattingLocale(newLocale: LocaleCodes) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  locale = newLocale;
}

export const formatDate = (
  value: string | number | Date,
  formatting: {
    month: "numeric" | "short";
    day: "numeric" | "2-digit";
    year: "numeric" | "2-digit";
  } = {
    month: "short",
    day: "numeric",
    year: "numeric",
  }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

export const formatTime = (
  timestamp: string | number | Date,
  locale: LocaleCodes = "en"
) => {
  return new Date(timestamp).toLocaleString(locale, {
    hour: "2-digit",
    minute: "2-digit",
  });
};
