import { compact, isNil, round, sum, uniq } from "lodash";

import {
  ConfirmReturnPayload,
  ConfirmReturnPaymentVariables,
  ConfirmReturnVariables,
  CreatePayPalOrderPayload,
  CreatePayPalOrderVariables,
  CreateShipmentPayload,
  CreateShipmentVariables,
  FetchOrderRegisteredItemsVariables,
  FetchOrderVariables,
  FetchProductDetailsVariables,
  FetchReturnMethodsNotesVariables,
  FetchReturnMethodsVariables,
  QueryParamItem,
  QueryParamParentKey,
} from "~/common/types/api";
import { MerchantSettings, ShopBackend } from "~/common/types/merchant";
import {
  ConfirmReturnOrderItemRaw,
  NoteAttribute,
  Order,
  OrderItem,
} from "~/common/types/order";
import { AuthorizePayPalOrderResponse } from "~/common/types/paypal";
import { RefundType, ReturnType, StoreCreditType } from "~/common/types/return";
import { getInitiatedReturnItemOriginalOrderItem } from "~/hooks/useCreateShipment/useCreateShipment.selectors";
import { InitiatedReturnItem } from "~/hooks/useReturnState/useReturnState.types";

import { getCountryLabel } from "./getCountryLabel";
import { transformArrayToCommaSeparatedString } from "./transformArrayToCommaSeparatedString";
import { isNotNil } from "./isNotNil";

export const getFetchOrderQueryString = ({
  orderId,
  customerEmail,
  currentPage,
}: FetchOrderVariables) => {
  const queryParamItems: QueryParamItem[] = [
    {
      childKey1: "invoice_number",
      value: orderId.trim(),
    },
    {
      childKey1: "customer",
      childKey2: "email",
      value: customerEmail.trim(),
    },
  ];
  if (currentPage)
    queryParamItems.push({
      childKey1: "current_page",
      value: currentPage,
    });
  return generateQueryString(queryParamItems, "filter");
};

export const getFetchReturnMethodsNotesQueryString = ({
  customerReturnId,
  shipments,
}: FetchReturnMethodsNotesVariables) => {
  const queryParamItems = [
    {
      childKey1: "shipment_ids",
      value: shipments.map(({ id }) => id),
    },
    {
      childKey1: "customer_return_id",
      value: customerReturnId,
    },
  ];

  return queryParamItems
    .map(({ childKey1, value }) => childKey1 + "=" + value)
    .join("&");
};

export const getFetchOrderRegisteredItemsQueryString = ({
  orderNumber,
  currentPage,
}: FetchOrderRegisteredItemsVariables) => {
  const queryParamItems: QueryParamItem[] = [
    {
      childKey1: "order_number",
      value: orderNumber,
    },
  ];
  if (currentPage)
    queryParamItems.push({
      childKey1: "current_page",
      value: currentPage,
    });
  return generateQueryString(queryParamItems, "filter");
};

export const getFetchReturnMethodsRequestPayload = ({
  customerCountry,
  orderAmount,
  numberOfItemsToReturn,
  returnReasonIds,
  returnType,
  customerTags,
  orderTags,
  paymentMethods,
  refundTotalValue,
  customerEmail,
  orderDate,
  returnTags = [],
  noOfRegisteredReturns,
  orderId,
}: FetchReturnMethodsVariables) => {
  const uniqueReturnTags = uniq(returnTags).join(",");
  return {
    rule: {
      order_country: customerCountry,
      order_value: orderAmount,
      number_of_items: numberOfItemsToReturn,
      return_reason_ids: returnReasonIds.join(","),
      return_type: returnType,
      customer_tags: customerTags,
      order_tags: orderTags,
      payment_method: paymentMethods,
      refund_total_value: refundTotalValue,
      customer_email: customerEmail,
      order_date: orderDate,
      order_registered_returns: noOfRegisteredReturns,
      return_item_product_tags: [
        {
          tag: uniqueReturnTags,
        },
      ],
      order_id: orderId,
    },
  };
};

export const generateQueryParamKeyName = (
  parentKey: QueryParamParentKey,
  { childKey1, childKey2, childKey3 }: Partial<QueryParamItem>
) => {
  if (childKey3) {
    return `${parentKey}[${childKey1}][${childKey2}][${childKey3}]`;
  }
  if (childKey2) {
    return `${parentKey}[${childKey1}][${childKey2}]`;
  }
  return `${parentKey}[${childKey1}]`;
};

export const generateQueryParams = (
  { value, childKey1, childKey2, childKey3 }: QueryParamItem,
  parentKey: QueryParamParentKey
) => {
  return `${generateQueryParamKeyName(parentKey, {
    childKey1,
    childKey2,
    childKey3,
  })}=${encodeURIComponent(value)}`;
};

export const generateQueryString = (
  items: QueryParamItem[],
  parentKey: QueryParamParentKey
) => {
  return items.map((item) => generateQueryParams(item, parentKey)).join("&");
};

export const getCreateShipmentRequestPayload = ({
  initiatedReturnItems = [],
  order = {},
  orderItems,
  ruleToken,
  returnAddress,
  customerAddress = {},
  customerName,
  returnMethod,
  merchant,
  customerEmail,
}: CreateShipmentVariables): CreateShipmentPayload => {
  return {
    shipment: {
      from: {
        first_name: customerName.firstName,
        last_name: customerName.lastName,
        street: customerAddress.street?.trim(),
        street_no: customerAddress.streetNumber?.trim(),
        city: customerAddress.city,
        zip_code: customerAddress.zip,
        country_iso2: customerAddress.countryCode,
        state: customerAddress.state,
      },
      to: {
        company: returnAddress.name2,
        last_name: returnAddress.name,
        street: returnAddress.street,
        street_no: returnAddress.streetNumber,
        city: returnAddress.city,
        state: returnAddress.state,
        zip_code: returnAddress.zip.toString(),
        country_iso2: returnAddress.countryCode,
      },
      customs_document: {
        displayed_currency:
          getInitiatedReturnItemOriginalOrderItem(
            initiatedReturnItems[0],
            orderItems
          ).displayedCurrency ?? merchant.currency,
        items: initiatedReturnItems.map((initiatedReturnItem) => {
          if (initiatedReturnItem.returnType === ReturnType.TradeIn) {
            const { name } = initiatedReturnItem.tradeInItem;
            return {
              name,
              quantity: initiatedReturnItem.returnQuantity,
              unit_weight_in_grams: 0,
              unit_price: 0.0,
              inventory_item_id: "",
            };
          }

          const orderItem = getInitiatedReturnItemOriginalOrderItem(
            initiatedReturnItem,
            orderItems
          );
          return {
            name: orderItem.name,
            quantity: initiatedReturnItem.returnQuantity,
            unit_weight_in_grams: Math.round(orderItem.weightInKg * 1000),
            unit_price: orderItem.displayedPrice,
            inventory_item_id: orderItem.inventoryItemId,
          };
        }),
      },
      reference_number: getShipmentReferenceNumber(merchant, order),
      shop_order_id: order.id,
      notification_email: customerEmail,
      package_weight: getShipmentPackageWeight(
        initiatedReturnItems,
        orderItems
      ),
      merchant_return_method_id: returnMethod.id,
      pickup_date: null,
      rule_token: ruleToken,
    },
  };
};

export const getShipmentReferenceNumber = (
  merchant: MerchantSettings,
  order: DeepPartial<Order>
) => {
  if (merchant.shopBackend === ShopBackend.Shopify) {
    const noteAttributesValue = getNoteAttributeValue(
      order.noteAttributes,
      merchant.returnAddressNoteAttributeName
    );
    if (noteAttributesValue?.length <= 30) {
      return noteAttributesValue;
    }
    return removeHashFromOrderName(order.orderName);
  }
  return order.orderNumber;
};

export const getShipmentPackageWeight = (
  initiatedReturnItems: InitiatedReturnItem[],
  orderItems: OrderItem[]
): number => {
  return (
    sum(
      initiatedReturnItems.map((initiatedReturnItem) => {
        const orderItem = getInitiatedReturnItemOriginalOrderItem(
          initiatedReturnItem,
          orderItems
        );
        // For tradeInItems We don't get weightInKg from the back-end
        // that's why we use 0 as a fallback
        return initiatedReturnItem.returnQuantity * (orderItem.weightInKg || 0);
      })
    ) ?? 0
  );
};

export const getNoteAttributeValue = (
  noteAttributes: Partial<NoteAttribute>[],
  noteAttributeName: string
): string => {
  const noteAttribute = noteAttributes.filter(
    ({ name }) => name === noteAttributeName
  );
  return noteAttribute[0]?.value?.toString();
};

export const removeHashFromOrderName = (orderName: string): string => {
  if (orderName.charAt(0) === "#") {
    return orderName.slice(1);
  }
  return orderName;
};

export const getConfirmReturnRequestPayload = ({
  order,
  orderItems,
  shipments,
  returnAddress,
  customerAddress = {},
  customerEmail,
  customerName: { firstName, lastName },
  ruleToken,
  language,
  returnMethod,
  initiatedReturnItems,
  refundType,
  payment,
  storeCreditOption,
  cartItems = [],
}: ConfirmReturnVariables): ConfirmReturnPayload => {
  const {
    senderCustomer = {},
    customer: {
      address: { company },
    },
  } = order;

  const tradeInItemsPayload = initiatedReturnItems.map((item) => {
    if (item.returnType === ReturnType.TradeIn) {
      const {
        tradeInItem: { id },
        returnQuantity: quantity,
      } = item;

      return { id, quantity };
    }
  });

  const orderInitiatedReturnItems = initiatedReturnItems.filter(
    (item) => item.returnType !== ReturnType.TradeIn
  );

  const paymentPayload = getConfirmReturnPaymentPayload(payment);

  return {
    refund: {
      items: getItemsPayload(orderInitiatedReturnItems, orderItems),
      trade_in_items: compact(tradeInItemsPayload),
      exchange_items: cartItems.map(
        ({ price, id, selectedQuantity, productId }) => ({
          product_id: productId,
          variant_id: id,
          quantity: selectedQuantity,
          displayed_price: price,
        })
      ),
      customer: {
        name: `${senderCustomer.firstName} ${senderCustomer.lastName}`,
        first_name: senderCustomer.firstName,
        last_name: senderCustomer.lastName,
        email: customerEmail,
        address: {
          name: `${firstName} ${lastName}`,
          first_name: firstName,
          last_name: lastName,
          street: customerAddress.street?.trim(),
          street_number: customerAddress.streetNumber?.trim(),
          zip: customerAddress.zip,
          city: customerAddress.city,
          country: getCountryLabel(customerAddress.countryCode),
          country_iso2: customerAddress.countryCode,
          phone: "",
          province: "",
          street_2: customerAddress.street2?.trim(),
          name_2: "",
          company,
        },
      },
      return_address: {
        company: returnAddress.name2,
        last_name: returnAddress.name,
        street: returnAddress.street,
        street_no: returnAddress.streetNumber,
        city: returnAddress.city,
        state: returnAddress.state,
        zip_code: returnAddress.zip.toString(),
        country_iso2: returnAddress.countryCode,
      },
      order_name: removeHashFromOrderName(order.orderName),
      rule_token: ruleToken,
      vendor_order_number: order.orderNumber,
      invoice_number: order.invoiceNumber,
      merchant_return_method_id: returnMethod.id,
      order_id: order.id,
      language,
      order_shipping_cost: order.shippingCost,
      order_date: order.orderDate,
      order_shipping_method:
        order.shippingMethod ?? JSON.stringify(order.shippingMethod),
      note_attributes: JSON.stringify(order.noteAttributes),
      no_of_items: order.noOfItems,
      payment_methods: order.paymentMethods,
      shipments: shipments.map((shipment) => ({
        id: shipment.id,
        shipping_company: returnMethod?.returnMethodCarrier,
        shipping_method:
          returnMethod?.returnMethodName || returnMethod?.returnMethodCarrier,
        tracking_number: shipment.carrierTrackingNo,
        price: shipment.price,
      })),
      ...paymentPayload,
      refund_type: getConfirmReturnRefundType(refundType, storeCreditOption),
    },
  };
};

const getInitiatedReturnItemReasonImagesUrls = (
  initiatedReturn: InitiatedReturnItem
): string => {
  const images =
    initiatedReturn.returnReasonMoreDetails?.images.map(
      ({ uploadId, ...rest }) => ({
        ...rest,
        id: uploadId,
      })
    ) ?? [];
  return JSON.stringify(images);
};

const getItemsPayload = (
  orderInitiatedReturnItems,
  orderItems
): ConfirmReturnOrderItemRaw[] =>
  orderInitiatedReturnItems.map((initiatedReturn) => {
    const {
      id,
      name,
      additionalComments,
      sku,
      ean,
      discount,
      price,
      displayedPrice,
      displayedCurrency,
      categories,
      imageUrl,
      weightInKg,
      variantId,
      inventoryItemId,
      productId,
      originalQuantity,
    } = getInitiatedReturnItemOriginalOrderItem(initiatedReturn, orderItems);

    const exchangeVariant = initiatedReturn.exchangeVariant ?? {
      title: "",
      id: "",
    };

    return {
      id,
      name,
      additional_comments: additionalComments,
      sku,
      ean,
      category: transformArrayToCommaSeparatedString(categories),
      discount,
      price,
      original_quantity: originalQuantity,
      quantity: initiatedReturn.returnQuantity,
      displayed_price: displayedPrice,
      displayed_currency: displayedCurrency,
      amount: initiatedReturn.returnQuantity * price,
      image_url: imageUrl,
      weight_in_kg: weightInKg,
      return_reason_id: initiatedReturn.returnReasonId?.toString() ?? "",
      refund_reason_text: initiatedReturn.returnReasonMoreDetails?.text ?? "",
      return_reason_picture:
        getInitiatedReturnItemReasonImagesUrls(initiatedReturn),
      inventory_item_id: inventoryItemId,
      variant_id: variantId,
      product_id: productId,
      return_type: initiatedReturn.returnType,
      // Note: this is the title of the *exchange* variant (not the variant itself)
      variant_title: exchangeVariant.title,
      exchange_variant_id: exchangeVariant.id,
    };
  });

export const getCreatePayPalOrderRequestPayload = ({
  order,
  orderItems,
  returnAddress,
  customerAddress = {},
  customerEmail,
  customerName: { firstName, lastName },
  returnMethod,
  initiatedReturnItems,
  clientPaymentAmount,
  originalOrderShippingCost,
  isShopNowPaymentFlow = false,
  cartItems = [],
}: CreatePayPalOrderVariables): CreatePayPalOrderPayload => {
  const { senderCustomer = {} } = order;

  const orderInitiatedReturnItems = initiatedReturnItems.filter(
    (item) => item.returnType !== ReturnType.TradeIn
  );

  return {
    order: {
      items: getItemsPayload(orderInitiatedReturnItems, orderItems),
      shop_now_items: cartItems.map(
        ({ selectedQuantity, price, productTitle, sku }) => ({
          sku,
          name: productTitle,
          amount: selectedQuantity * price,
          quantity: selectedQuantity,
          price,
          return_type: "shop_exchange",
        })
      ),
      customer: {
        name: `${senderCustomer.firstName} ${senderCustomer.lastName}`,
        first_name: senderCustomer.firstName,
        last_name: senderCustomer.lastName,
        email: customerEmail,
        address: {
          name: `${firstName} ${lastName}`,
          first_name: firstName,
          last_name: lastName,
          street: customerAddress.street?.trim(),
          street_number: customerAddress.streetNumber?.trim(),
          zip: customerAddress.zip,
          city: customerAddress.city,
          country: getCountryLabel(customerAddress.countryCode),
          country_iso2: customerAddress.countryCode,
          phone: "",
          province: "",
          street_2: "",
          name_2: "",
        },
      },
      return_address: {
        company: returnAddress.name2,
        last_name: returnAddress.name,
        street: returnAddress.street,
        street_no: returnAddress.streetNumber,
        city: returnAddress.city,
        state: returnAddress.state,
        zip_code: returnAddress.zip.toString(),
        country_iso2: returnAddress.countryCode,
      },
      merchant_return_method_id: returnMethod.id,
      order_id: order.id,
      order_shipping_cost: originalOrderShippingCost,
      order_date: order.orderDate,
      order_shipping_method:
        order.shippingMethod ?? JSON.stringify(order.shippingMethod),
    },

    validation: {
      // Note: This is the value of payment amount as calculated on the front-end client
      client_payment_amount: round(clientPaymentAmount, 2),
    },

    // Note: this is hard-coded for now
    // PayPal integration is only available for Instant Store Credit feature
    payment_flow: isShopNowPaymentFlow ? "shop_now" : "instant_store_credit",
  };
};

export const getConfirmReturnPaymentPayload = (
  payment?: ConfirmReturnPaymentVariables
): Pick<ConfirmReturnPayload["refund"], "payment"> => {
  if (isNil(payment)) {
    return {};
  }
  const { authorizationId, orderId } = payment;
  return {
    payment: {
      order_id: orderId,
      authorization_id: authorizationId,
    },
  };
};

export const getConfirmReturnPaymentVariablesFromPaymentAuthorizationResponse =
  (
    authorizePaymentOrderResponse: AuthorizePayPalOrderResponse
  ): ConfirmReturnPaymentVariables => {
    const purchaseUnits = authorizePaymentOrderResponse?.purchase_units ?? [];
    const purchaseUnit = purchaseUnits[0];
    const authorizations = purchaseUnit?.payments?.authorizations ?? [];
    const authorization = authorizations[0];
    return {
      orderId: authorizePaymentOrderResponse?.id,
      authorizationId: authorization?.id,
    };
  };

export const getConfirmReturnRefundType = (
  refundType: RefundType,
  storeCreditOption: StoreCreditType
): ConfirmReturnPayload["refund"]["refund_type"] => {
  switch (refundType) {
    case RefundType.OriginalPaymentMethod:
      return RefundType.OriginalPaymentMethod;
    case RefundType.StoreCredit:
      return storeCreditOption;
    case RefundType.ShopExchange:
      return RefundType.ShopExchange;
    default:
      return null;
  }
};

export const getFetchProductDetailsQueryString = ({
  orderId,
  exchangedOrderItemId,
}: Pick<FetchProductDetailsVariables, "orderId" | "exchangedOrderItemId">) => {
  if (isNotNil(orderId) && isNotNil(exchangedOrderItemId)) {
    return `?order_id=${orderId}&order_item_id=${exchangedOrderItemId}`;
  }
  return "";
};
