import React, { createContext, FC, useContext, useState } from "react";

import { emptyCallback } from "~/util/emptyCallback";

export type CartVisibilityContextType = {
  setIsCartVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCartVisible: boolean;
};

export const CartVisibilityContext = createContext<CartVisibilityContextType>({
  setIsCartVisible: emptyCallback,
  isCartVisible: false,
});

export const CartVisibilityContextProvider: FC = ({ children }) => {
  const [isCartVisible, setIsCartVisible] = useState(false);
  return (
    <CartVisibilityContext.Provider
      value={{
        isCartVisible,
        setIsCartVisible,
      }}
    >
      {children}
    </CartVisibilityContext.Provider>
  );
};

export const useCartVisibilityContext = () => useContext(CartVisibilityContext);
