import { ShopBackend, ShopBackendRaw } from "~/common/types/merchant";
import {
  RefundShippingCostPolicy,
  RefundShippingCostPolicyRaw,
} from "~/common/types/return";
import { transformCommaSeparatedStringToArray } from "~/util/transformCommaSeparatedStringToArray";
import { LocaleCodes } from "~/common/types/common";

import {
  MerchantSettingsResponse,
  MerchantSettingsResponseRaw,
} from "./useMerchantSettings.types";

export const transformMerchantSettingsResponse = (
  responseData: MerchantSettingsResponseRaw
): MerchantSettingsResponse => {
  const settings = responseData?.settings;
  if (settings) {
    const {
      image_url,
      primary_color,
      secondary_color,
      shop_url,
      custom_script,
    } = settings.checkout_view_setting ?? {};
    const {
      name,
      currency = "EUR",
      climate_compensation,
      return_address1_note_attribute_name,
      shop_backend,
      show_exchange,
      show_store_credit,
      show_instant_store_credit,
      show_trade_in_items,
      merchant_languages,
      shop_now_exchange_enabled,
      active_paypal_checkout,
    } = settings.merchant ?? {};
    const {
      policy_url,
      expected_refund,
      refund_shipping_cost,
      custom_return_name,
      exchange_without_track_quantity,
      store_credit_added_percentage,
      custom_missing_optional_translations,
    } = settings.return_policy ?? {};
    const returnReasons = settings.return_reasons ?? [];
    const merchantLanguages = merchant_languages ?? [];
    return {
      viewSettings: {
        primaryColor: primary_color,
        secondaryColor: secondary_color,
        logoUrl: image_url,
        shopUrl: shop_url,
        customScript: custom_script,
      },
      locales: merchantLanguages.map(
        ({ lang_code }) => lang_code as LocaleCodes
      ),
      merchant: {
        name,
        currency,
        climateCompensation: climate_compensation,
        returnAddressNoteAttributeName: return_address1_note_attribute_name,
        shopBackend: shopBackendMap[shop_backend],
        showExchange: show_exchange,
        // Note: return_policy.show_custom_return is set by the merchant
        // merchant.show_custom_return is set by 8returns admins (to overwrite if needed, i.e. paywall)
        showCustomReturn:
          settings.merchant?.show_custom_return &&
          settings.return_policy?.show_custom_return,
        showStoreCredit: show_store_credit,
        showInstantStoreCredit: show_instant_store_credit,
        showTradeInItems: show_trade_in_items,
        shopNowEnabled: shop_now_exchange_enabled,
        shopNowPaymentEnabled: active_paypal_checkout,
        merchantLanguages: merchantLanguages.map(
          ({ lang_code, lang_name }) => ({
            langCode: lang_code,
            langName: lang_name,
          })
        ),
      },
      returnPolicy: {
        policyUrl: policy_url,
        showExpectedRefund: expected_refund,
        refundShippingCost: refundShippingCostPolicyMap[refund_shipping_cost],
        customReturnName: custom_return_name,
        exchangeWithoutTrackQuantity: exchange_without_track_quantity,
        storeCreditAddedPercentage: store_credit_added_percentage || 0,
        // Note: For the first iteration, the additional bonus for in-app shopping is set to 0
        // In next iteration, this value should be returned from the backend
        inAppShoppingAddedPercentage: 0,
        customMissingOptionalTranslations: custom_missing_optional_translations,
      },
      returnReasons: returnReasons.map(
        ({
          id,
          external_text_by_locale,
          additional_text_required,
          additional_text,
          image_upload,
          image_upload_required,
          text_title_by_locale,
          show_for_refund,
          show_for_exchange_reasons,
          show_for_custom_reasons,
          applicable_categories = "",
        }) => ({
          id,
          externalText: external_text_by_locale,
          isAdditionalTextSupported: additional_text,
          isAdditionalTextRequired: additional_text_required,
          isImageSupported: image_upload,
          isImageRequired: image_upload_required,
          textTitle: text_title_by_locale,
          visible: {
            refund: show_for_refund,
            exchange: show_for_exchange_reasons,
            custom: show_for_custom_reasons,
          },
          applicableCategories: transformCommaSeparatedStringToArray(
            applicable_categories
          ),
        })
      ),
    };
  }
  return {
    viewSettings: {},
    locales: [],
    merchant: {
      currency: "EUR",
      merchantLanguages: [],
    },
    returnPolicy: {},
    returnReasons: [],
  };
};

export const shopBackendMap: Record<ShopBackendRaw, ShopBackend> = {
  shopify_integration: ShopBackend.Shopify,
  shopware_integration: ShopBackend.Shopware,
};

export const refundShippingCostPolicyMap: Record<
  RefundShippingCostPolicyRaw,
  RefundShippingCostPolicy
> = {
  refund: RefundShippingCostPolicy.Refund,
  no_refund: RefundShippingCostPolicy.NoRefund,
  if_all_items_returned: RefundShippingCostPolicy.IfAllItemsReturned,
};
