import { useMemo } from "react";
import { useMutation } from "react-query";

import { createShipment } from "~/api";

import { transformCreateShipmentResponse } from "./useCreateShipment.util";

export const useCreateShipment = () => {
  const {
    mutate,
    data: response,
    isError,
    isLoading,
  } = useMutation("createShipmentMutation", createShipment);
  const data = useMemo(
    () => transformCreateShipmentResponse(response),
    [response]
  );
  return {
    createShipment: mutate,
    data,
    isError,
    isLoading,
  };
};
