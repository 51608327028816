import { useMemo } from "react";
import { useQuery } from "react-query";

import { fetchMerchantSettings } from "~/api";

import { transformMerchantSettingsResponse } from "./useMerchantSettings.util";

export const useMerchantSettings = () => {
  const { data: response, ...restProps } = useQuery(
    "merchantSettingsQuery",
    fetchMerchantSettings
  );
  const data = useMemo(
    () => transformMerchantSettingsResponse(response),
    [response]
  );
  return {
    data,
    ...restProps,
  };
};
