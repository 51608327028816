import React, { createContext, FC, useContext, useMemo } from "react";

import { LocaleCodes } from "~/common/types/common";
import { useLanguage } from "~/hooks/useLanguage/useLanguage";
import { emptyCallback } from "~/util/emptyCallback";

export type LanguageContextType = {
  language: LocaleCodes;
  changeLanguage: (language: string) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  language: "en" as LocaleCodes,
  changeLanguage: emptyCallback,
});

export type LanguageContextProviderProps = {
  merchantLanguages: LocaleCodes[];
};

export const LanguageContextProvider: FC<LanguageContextProviderProps> = ({
  children,
  merchantLanguages,
}) => {
  const { language, changeLanguage } = useLanguage({ merchantLanguages });

  const value = useMemo(
    () => ({
      language,
      changeLanguage,
    }),
    [language, changeLanguage]
  );
  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);
