import { useState, useEffect } from "react";

// Adapted from https://www.robinwieruch.de/react-uselocalstorage-hook/
export const useSessionStorage = <T>(storageKey: string, fallbackState: T) => {
  const [value, setValue] = useState<T>(
    JSON.parse(sessionStorage.getItem(storageKey)) ?? fallbackState
  );

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue] as const;
};
