import React, { createContext, FC, useContext } from "react";
import { UseMutateFunction } from "react-query";

import { ConfirmReturnVariables } from "~/common/types/api";
import { useConfirmReturn } from "~/hooks/useConfirmReturn/useConfirmReturn";
import {
  ConfirmReturnResponse,
  ConfirmReturnResponseRaw,
} from "~/hooks/useConfirmReturn/useConfirmReturn.types";
import { emptyCallback } from "~/util/emptyCallback";

export type ConfirmReturnContextType = {
  confirmReturn: UseMutateFunction<
    ConfirmReturnResponseRaw,
    Error,
    ConfirmReturnVariables,
    unknown
  >;
  data: DeepPartial<ConfirmReturnResponse>;
  isError: boolean;
  isLoading: boolean;
};

export const ConfirmReturnContext = createContext<ConfirmReturnContextType>({
  confirmReturn: emptyCallback,
  data: {},
  isError: false,
  isLoading: false,
});

export const ConfirmReturnContextProvider: FC = ({ children }) => {
  const value = useConfirmReturn();
  return (
    <ConfirmReturnContext.Provider value={value}>
      {children}
    </ConfirmReturnContext.Provider>
  );
};

export const useConfirmReturnContext = () => useContext(ConfirmReturnContext);
