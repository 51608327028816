import { queryCache } from "~/components/common/ReactQueryClientProvider/ReactQueryClientProvider";

export async function importWithRetry(
  importFn: () => Promise<any>,
  retries = 5,
  interval = 1000
) {
  try {
    return await importFn();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return importWithRetry(importFn, retries - 1, interval);
    } else {
      clearCache();
      window.location.href = "/";
    }
  }
}

function clearCache() {
  sessionStorage.clear();
  queryCache.clear();
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
