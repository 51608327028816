import { useCallback, useMemo, useState } from "react";
import { omit } from "lodash";

import { Product, ProductVariant } from "~/common/types/product";
import {
  RefundType,
  ReturnType,
  ShopExchangeRefundType,
  StoreCreditType,
} from "~/common/types/return";
import { useSessionStorage } from "../useSessionStorage/useSessionStorage";
import { isNotNil } from "~/util/isNotNil";

import {
  Cart,
  InitiatedReturnItem,
  ReturnReasonImageData,
} from "./useReturnState.types";
import { calculateCartItemsCost } from "./useReturnState.util";

export const useReturnState = () => {
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedTradeInItemId, setSelectedTradeInItemId] = useState(-1);
  const [selectedReturnQuantity, setSelectedReturnQuantity] = useState(1);
  const [selectedReturnReasonId, setSelectedReturnReasonId] = useState(-1);
  const [returnReasonImages, setReturnReasonImages] = useState<
    ReturnReasonImageData[]
  >([]);
  const [returnReasonText, setReturnReasonText] = useState("");
  const [selectedReturnMethodId, setSelectedReturnMethodId] = useState(-1);
  const [initiatedReturnItems, setInitiatedReturnItems] = useSessionStorage<
    InitiatedReturnItem[]
  >("initiatedReturnItems", []);

  const [selectedReturnType, setSelectedReturnType] =
    useState<ReturnType>(null);

  const [selectedExchangeVariant, setSelectedExchangeVariant] =
    useState<DeepPartial<ProductVariant>>();

  const [selectedRefundType, setSelectedRefundType] = useState<RefundType>(
    RefundType.OriginalPaymentMethod
  );

  const [selectedShopExchangeRefundType, setSelectedShopExchangeRefundType] =
    useState<ShopExchangeRefundType>(
      ShopExchangeRefundType.OriginalPaymentMethod
    );

  const [selectedStoreCreditOption, setSelectedStoreCreditOption] =
    useState<StoreCreditType>(null);

  const [selectedCollectionId, setSelectedCollectionId] =
    useState<string>(null);

  const [selectedShoppingProductId, setSelectedShoppingProductId] =
    useState<string>(null);

  const [cart, setCart] = useState<Cart>({});

  const addItemToCart = useCallback(
    (
      product: DeepPartial<Product>,
      selectedProductVariant: DeepPartial<ProductVariant>
    ) => {
      const { id, title, price, image, isDefaultVariant, sku } =
        selectedProductVariant;

      setCart((prevCart) => {
        const isItemAlreadyExists = isNotNil(prevCart[id]);
        if (isItemAlreadyExists) {
          const prevCartItem = prevCart[id];
          const updatedCartItem = {
            ...prevCartItem,
            selectedQuantity: prevCartItem.selectedQuantity + 1,
          };
          return { ...prevCart, [id]: updatedCartItem };
        }
        return {
          ...prevCart,
          [id]: {
            productId: product.globalId,
            productTitle: product.title,
            selectedQuantity: 1,
            id,
            title,
            price,
            image,
            isDefaultVariant,
            sku,
          },
        };
      });
    },
    []
  );

  const removeItemFromCart = useCallback(
    (selectedProductVariant: DeepPartial<ProductVariant>) => {
      const { id } = selectedProductVariant;
      setCart((prevCart) => omit(prevCart, [id]));
    },
    []
  );

  const changeItemQuantityInCart = useCallback(
    (
      selectedProductVariant: DeepPartial<ProductVariant>,
      increment: 1 | -1
    ) => {
      const { id } = selectedProductVariant;
      setCart((prevCart) => {
        const prevCartItem = prevCart[id];
        const updatedCartItem = {
          ...prevCartItem,
          selectedQuantity: prevCartItem.selectedQuantity + increment,
        };
        return { ...prevCart, [id]: updatedCartItem };
      });
    },
    []
  );

  const resetSelectedReturnMethod = useCallback(() => {
    setSelectedReturnMethodId(-1);
  }, []);

  const resetInAppShoppingState = useCallback(() => {
    setCart({});
    setSelectedShoppingProductId(null);
    setSelectedCollectionId(null);
  }, []);

  const cartItems = useMemo(() => Object.values(cart), [cart]);

  const cartItemsTotalCost = useMemo(
    () => calculateCartItemsCost(cartItems),
    [cartItems]
  );

  const addInitiatedReturnItem = useCallback(
    (initiatedReturnItem: InitiatedReturnItem) => {
      setInitiatedReturnItems((existingItems) => [
        ...existingItems,
        initiatedReturnItem,
      ]);
    },
    []
  );

  const removeInitiatedReturnItem = useCallback(
    (idToRemove: string | number) => {
      setInitiatedReturnItems((existingItems) =>
        existingItems.filter(
          ({ orderItemId, tradeInItem: { id: tradeInItemId } = {} }) => {
            if (orderItemId) {
              return orderItemId !== idToRemove;
            } else if (tradeInItemId) {
              return tradeInItemId !== idToRemove;
            }

            return false;
          }
        )
      );
    },
    []
  );

  const resetCurrentItemReturnState = useCallback(() => {
    setSelectedItemId("");
    setSelectedReturnQuantity(1);
    setSelectedReturnReasonId(-1);
    setReturnReasonText("");
    setReturnReasonImages([]);
    setSelectedReturnMethodId(-1);
    setSelectedReturnType(null);
    setSelectedExchangeVariant(null);
    setSelectedStoreCreditOption(null);
  }, []);

  const resetReturnState = useCallback(() => {
    resetCurrentItemReturnState();
    setInitiatedReturnItems([]);
    setSelectedRefundType(RefundType.OriginalPaymentMethod);
    setSelectedShopExchangeRefundType(
      ShopExchangeRefundType.OriginalPaymentMethod
    );
  }, []);

  return {
    selectedItemId,
    setSelectedItemId,
    selectedTradeInItemId,
    setSelectedTradeInItemId,
    selectedReturnQuantity,
    setSelectedReturnQuantity,
    selectedReturnMethodId,
    setSelectedReturnMethodId,
    initiatedReturnItems,
    addInitiatedReturnItem,
    removeInitiatedReturnItem,
    selectedReturnReasonId,
    setSelectedReturnReasonId,
    returnReasonImages,
    setReturnReasonImages,
    returnReasonText,
    setReturnReasonText,
    selectedReturnType,
    setSelectedReturnType,
    selectedExchangeVariant,
    setSelectedExchangeVariant,
    selectedRefundType,
    setSelectedRefundType,
    selectedShopExchangeRefundType,
    setSelectedShopExchangeRefundType,
    resetReturnState,
    resetCurrentItemReturnState,
    selectedStoreCreditOption,
    setSelectedStoreCreditOption,
    selectedCollectionId,
    setSelectedCollectionId,
    selectedShoppingProductId,
    setSelectedShoppingProductId,
    cartItems,
    cartItemsTotalCost,
    addItemToCart,
    removeItemFromCart,
    changeItemQuantityInCart,
    resetInAppShoppingState,
    resetSelectedReturnMethod,
  };
};
