import React, { createContext, FC, useContext } from "react";
import { UseMutateFunction } from "react-query";

import { CreateShipmentVariables } from "~/common/types/api";
import { useCreateShipment } from "~/hooks/useCreateShipment/useCreateShipment";
import {
  CreateShipmentResponse,
  CreateShipmentResponseRaw,
} from "~/hooks/useCreateShipment/useCreateShipment.types";
import { emptyCallback } from "~/util/emptyCallback";

export type ShipmentContextType = {
  createShipment: UseMutateFunction<
    CreateShipmentResponseRaw,
    Error,
    CreateShipmentVariables
  >;
  isError: boolean;
  isLoading: boolean;
  data: CreateShipmentResponse;
};

export const ShipmentContext = createContext<ShipmentContextType>({
  createShipment: emptyCallback,
  isError: false,
  isLoading: false,
  data: null,
});

export const ShipmentContextProvider: FC = ({ children }) => {
  const value = useCreateShipment();
  return (
    <ShipmentContext.Provider value={value}>
      {children}
    </ShipmentContext.Provider>
  );
};

export const useShipmentContext = () => useContext(ShipmentContext);
