import React, { Suspense, FC } from "react";
import { MemoryRouter, Switch, Route } from "react-router-dom";

import Error from "../components/Error/Error";
import { ScrollToTop } from "../components/common/ScrollToTop/ScrollToTop";
// import { isUserLoggedIn } from "../util/isUserLoggedIn";
// import { getUserData } from "../util/getUserData";
import { FallbackSpinner } from "~/ui-kit/components/FallbackSpinner/FallbackSpinner";

import { appRoutes } from "./routes/routes";

export const Router: FC = () => {
  // Note: Using MemoryRouter to avoid having to deal with the complexity
  // Of handling state changes when the user uses default browser navigation
  // To use BrowserRouter, just use <BrowserRouter basename="/v3"></BrowserRouter>
  return (
    <MemoryRouter>
      <Switch>
        {appRoutes.map(({ Component, path, exact }, i) => {
          return (
            <Route
              key={`${i}-${path}`}
              path={path}
              exact={exact === true}
              render={(props) => {
                return (
                  <Suspense fallback={<FallbackSpinner />}>
                    <ScrollToTop />
                    <Component {...props} />
                  </Suspense>
                );
              }}
            />
          );
        })}
        <Route path="*" component={Error} />
      </Switch>
    </MemoryRouter>
  );
};
