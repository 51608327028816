import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
} from "react";

import { Product, ProductVariant } from "~/common/types/product";
import {
  RefundType,
  ReturnType,
  ShopExchangeRefundType,
  StoreCreditType,
} from "~/common/types/return";
import { useReturnState } from "~/hooks/useReturnState/useReturnState";
import {
  CartItem,
  InitiatedReturnItem,
  ReturnReasonImageData,
} from "~/hooks/useReturnState/useReturnState.types";
import { emptyCallback } from "~/util/emptyCallback";

export type ReturnContextType = {
  selectedItemId: string;
  setSelectedItemId: (selectedItemId: string) => void;
  selectedReturnQuantity: number;
  setSelectedReturnQuantity: (quantity: number) => void;
  selectedReturnMethodId: number;
  setSelectedReturnMethodId: (returnMethodId: number) => void;
  initiatedReturnItems: InitiatedReturnItem[];
  addInitiatedReturnItem: (initiatedReturnItem: InitiatedReturnItem) => void;
  removeInitiatedReturnItem: (orderItemId: string | number) => void;
  selectedReturnReasonId: number;
  setSelectedReturnReasonId: (reasonId: number) => void;
  returnReasonImages: ReturnReasonImageData[];
  setReturnReasonImages: Dispatch<SetStateAction<ReturnReasonImageData[]>>;
  returnReasonText: string;
  setReturnReasonText: (returnReasonText: string) => void;
  resetReturnState: () => void;
  resetCurrentItemReturnState: () => void;
  selectedReturnType: ReturnType;
  setSelectedReturnType: (returnType: ReturnType) => void;
  selectedExchangeVariant: DeepPartial<ProductVariant>;
  setSelectedExchangeVariant: (
    selectedExchangeVariant: DeepPartial<ProductVariant>
  ) => void;
  selectedRefundType: RefundType;
  setSelectedRefundType: (refundType: RefundType) => void;
  selectedShopExchangeRefundType: ShopExchangeRefundType;
  setSelectedShopExchangeRefundType: (
    shopExchangeRefundType: ShopExchangeRefundType
  ) => void;
  selectedTradeInItemId: number;
  setSelectedTradeInItemId: (selectedTradeInItemId: number) => void;
  selectedStoreCreditOption: StoreCreditType;
  setSelectedStoreCreditOption: (storeCreditOption: StoreCreditType) => void;
  selectedCollectionId: string;
  setSelectedCollectionId: (selectedCollectionId: string) => void;
  selectedShoppingProductId: string;
  setSelectedShoppingProductId: (selectedShoppingProductId: string) => void;
  cartItems: CartItem[];
  removeItemFromCart: (
    selectedProductVariant: DeepPartial<ProductVariant>
  ) => void;
  addItemToCart: (
    product: DeepPartial<Product>,
    selectedProductVariant: DeepPartial<ProductVariant>
  ) => void;
  changeItemQuantityInCart: (
    selectedProductVariant: DeepPartial<ProductVariant>,
    increment: 1 | -1
  ) => void;
  cartItemsTotalCost: number;
  resetInAppShoppingState: () => void;
  resetSelectedReturnMethod: () => void;
};

export const ReturnContext = createContext<ReturnContextType>({
  selectedItemId: "",
  setSelectedItemId: emptyCallback,
  selectedReturnQuantity: 1,
  setSelectedReturnQuantity: emptyCallback,
  selectedReturnMethodId: -1,
  setSelectedReturnMethodId: emptyCallback,
  initiatedReturnItems: [],
  addInitiatedReturnItem: emptyCallback,
  removeInitiatedReturnItem: emptyCallback,
  selectedReturnReasonId: -1,
  setSelectedReturnReasonId: emptyCallback,
  returnReasonImages: [],
  setReturnReasonImages: emptyCallback,
  returnReasonText: "",
  setReturnReasonText: emptyCallback,
  resetReturnState: emptyCallback,
  resetCurrentItemReturnState: emptyCallback,
  selectedReturnType: null,
  setSelectedReturnType: emptyCallback,
  selectedExchangeVariant: null,
  setSelectedExchangeVariant: emptyCallback,
  selectedRefundType: null,
  setSelectedRefundType: emptyCallback,
  selectedShopExchangeRefundType: null,
  setSelectedShopExchangeRefundType: emptyCallback,
  selectedTradeInItemId: -1,
  setSelectedTradeInItemId: emptyCallback,
  selectedStoreCreditOption: null,
  setSelectedStoreCreditOption: emptyCallback,
  selectedCollectionId: null,
  setSelectedCollectionId: emptyCallback,
  selectedShoppingProductId: null,
  setSelectedShoppingProductId: emptyCallback,
  addItemToCart: emptyCallback,
  removeItemFromCart: emptyCallback,
  cartItems: [],
  changeItemQuantityInCart: emptyCallback,
  cartItemsTotalCost: 0,
  resetInAppShoppingState: emptyCallback,
  resetSelectedReturnMethod: emptyCallback,
});

export const ReturnContextProvider: FC = ({ children }) => {
  const value = useReturnState();
  return (
    <ReturnContext.Provider value={value}>{children}</ReturnContext.Provider>
  );
};

export const useReturnContext = () => useContext(ReturnContext);
