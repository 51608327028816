/* TODO: Convert this file to TS file if possible */
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";

import { App } from "./App";
import { ReactQueryClientProvider } from "./components/common/ReactQueryClientProvider/ReactQueryClientProvider";
import { AppProviders } from "./components/common/AppProviders/AppProviders";

import "./common/i18n/index";

import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "~/util/mixpanel";

const Root = () => {
  return (
    <ReactQueryClientProvider>
      <AppProviders>
        <App />
        <ToastContainer newestOnTop className="w-100" />
      </AppProviders>
    </ReactQueryClientProvider>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Root />, document.getElementById("root"));
});
