import { useMemo } from "react";
import { useMutation } from "react-query";

import { fetchReturnMethods } from "~/api";
import { FetchReturnMethodsVariables } from "~/common/types/api";

import { ReturnMethodsResponseRaw } from "./useReturnMethods.types";
import { transformReturnMethodsResponse } from "./useReturnMethods.util";

export type UseReturnMethodsProps = {
  variables: FetchReturnMethodsVariables;
};

export const useReturnMethods = () => {
  const {
    data: response,
    mutate,
    ...restProps
  } = useMutation<ReturnMethodsResponseRaw, Error, FetchReturnMethodsVariables>(
    "returnMethodsMutation",
    fetchReturnMethods
  );
  const data = useMemo(
    () => transformReturnMethodsResponse(response),
    [response]
  );
  return {
    data,
    fetchReturnMethods: mutate,
    ...restProps,
  };
};
