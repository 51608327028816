import { useSessionStorage } from "~/hooks/useSessionStorage/useSessionStorage";

export const useLoginState = () => {
  const [orderId, setOrderId] = useSessionStorage("orderId", "");
  const [customerEmail, setCustomerEmail] = useSessionStorage(
    "customerEmail",
    ""
  );
  const isDisableCTA = orderId === "" || customerEmail === "";
  const resetLoginState = () => {
    setOrderId("");
    setCustomerEmail("");
  };
  return {
    orderId,
    customerEmail,
    setOrderId,
    setCustomerEmail,
    isDisableCTA,
    resetLoginState,
  };
};
