import mixpanel from "mixpanel-browser";

import { RefundType } from "~/common/types/return";
import { Product, ProductVariant } from "~/common/types/product";
import {
  CartItem,
  InitiatedReturnItem,
} from "~/hooks/useReturnState/useReturnState.types";
import { OrderItem } from "~/common/types/order";

import { isProductionEnvironment } from "./isProductionEnvironment";

// Note: These project tokens are not a secret, rather a project identifier
// Therefore it can be safely stored in the client
const MIXPANEL_PROD_TOKEN = "15e1ede12f79c0aa7b83e95e7578e34a";
const MIXPANEL_TEST_TOKEN = "47ff8cd048e0ca1dd843b0b521c56b02";

const isProduction = isProductionEnvironment();

const getMixpanelProjectToken = () => {
  return isProduction ? MIXPANEL_PROD_TOKEN : MIXPANEL_TEST_TOKEN;
};

mixpanel.init(getMixpanelProjectToken(), { debug: !isProduction });

const mixpanelStoreUserProfile = (
  merchantName: string,
  customerEmail: string,
  orderId: string
) => {
  mixpanel.people.set({ "Merchant Name": merchantName });
  mixpanel.people.set({ $email: customerEmail });
  mixpanel.people.append({ "Order IDs": orderId });
};

const mixpanelIdentifyUser = (customerEmail: string) =>
  mixpanel.identify(customerEmail);

export const mixpanelTrackUser = ({
  merchantName,
  customerEmail,
  orderId,
}: {
  merchantName: string;
  customerEmail: string;
  orderId: string;
}) => {
  mixpanelIdentifyUser(customerEmail);
  mixpanelStoreUserProfile(merchantName, customerEmail, orderId);
};

export const trackRefundTypeSelectedEvent = (refundType: RefundType) => {
  const textByRefundTypeMap = {
    [RefundType.OriginalPaymentMethod]: "Original Payment Method",
    [RefundType.StoreCredit]: "Store Credit",
    [RefundType.ShopExchange]: "Shop Now",
  };
  mixpanel.track("Refund selected", {
    "Refund Type": textByRefundTypeMap[refundType],
  });
};

export const trackRefundTooltipTriggeredEvent = (
  tooltipTitle: string,
  interaction: "click" | "hover"
) => {
  mixpanel.track("Refund tooltip triggered", {
    Title: tooltipTitle,
    Interaction: interaction,
  });
};

export const trackShopNowRefundSelectedEvent = () => {
  mixpanel.track("Shop Now refund type selected");
};

export const trackProductAddedToCartEvent = (
  product: DeepPartial<Product>,
  selectedVariant: DeepPartial<ProductVariant>
) => {
  mixpanel.track("Product added to cart", {
    "Product ID": product.globalId,
    "Variant ID": selectedVariant.id,
  });
};

export const trackProductRemovedFromCartEvent = () => {
  mixpanel.track("Product removed from cart");
};

export const trackCollectionSeeAllClickedEvent = () => {
  mixpanel.track(`Collection "See All" clicked`);
};

export const trackBrowseAllClickedEvent = () => {
  mixpanel.track("Browse All clicked");
};

export const trackCollectionClickedEvent = () => {
  mixpanel.track("Collection page opened");
};

export const trackProductClickedEvent = () => {
  mixpanel.track("Product page opened");
};

export const trackCartCheckoutClickedEvent = (cartItems: CartItem[]) => {
  mixpanel.track("Checkout button clicked", {
    "Cart Items": cartItems.map(({ productId, id, selectedQuantity }) => ({
      "Product ID": productId,
      "Variant ID": id,
      "Selected Quantity": selectedQuantity,
    })),
  });
};

export const trackPaginationNextButtonClickedEvent = () => {
  mixpanel.track("Pagination next button clicked");
};

export const trackPaginationPreviousButtonClickedEvent = () => {
  mixpanel.track("Pagination previous button clicked");
};

export const trackConfirmReturnButtonClickedEvent = (
  returnItems: (InitiatedReturnItem & DeepPartial<OrderItem>)[],
  cartItems?: CartItem[]
) => {
  const hasCartItems = cartItems && cartItems.length;
  const cartItemsEventContext = hasCartItems
    ? {
        "Cart Items": cartItems.map(({ productId, id, selectedQuantity }) => ({
          "Product ID": productId,
          "Variant ID": id,
          "Selected Quantity": selectedQuantity,
        })),
      }
    : {};
  const eventContext = {
    "Returned Items": returnItems.map(
      ({ productId, variantId, returnQuantity }) => ({
        "Product ID": productId,
        "Variant ID": variantId,
        "Selected Quantity": returnQuantity,
      })
    ),
    ...cartItemsEventContext,
  };
  mixpanel.track("Confirm return button clicked", eventContext);
};
