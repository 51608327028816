export type CheckoutViewSettingsRaw = DeepPartial<{
  de_language: boolean;
  fr_language: boolean;
  image_url: string;
  instructions: any;
  primary_color: string;
  secondary_color: string;
  shop_url: string;
  custom_script: string;
}>;

export type MerchantLanguageRaw = DeepPartial<{
  lang_name: string;
  lang_code: string;
}>;

export type MerchantLanguage = DeepPartial<{
  langName: string;
  langCode: string;
}>;

export type MerchantSettingsRaw = DeepPartial<{
  climate_compensation: boolean;
  currency: string;
  name: string;
  return_address1_note_attribute_name: string;
  shop_backend: ShopBackendRaw;
  show_custom_return: boolean;
  show_exchange: boolean;
  show_store_credit: boolean;
  show_instant_store_credit: boolean;
  show_trade_in_items: boolean;
  merchant_languages: MerchantLanguageRaw[];
  shop_now_exchange_enabled: boolean;
  active_paypal_checkout: boolean;
}>;

export type CheckoutViewSettings = DeepPartial<{
  logoUrl: string;
  shopUrl: string;
  primaryColor: string;
  secondaryColor: string;
  customScript: string;
}>;

export type ShopBackendRaw = `${ShopBackend}`;

export enum ShopBackend {
  Shopify = "shopify_integration",
  Shopware = "shopware_integration",
}

export type MerchantSettings = DeepPartial<{
  climateCompensation: boolean;
  currency: string;
  name: string;
  shopBackend: ShopBackend;
  showCustomReturn: boolean;
  showExchange: boolean;
  showStoreCredit: boolean;
  showInstantStoreCredit: boolean;
  returnAddressNoteAttributeName: string;
  showTradeInItems: boolean;
  shopNowEnabled: boolean;
  shopNowPaymentEnabled: boolean;
  merchantLanguages: MerchantLanguage[];
}>;
