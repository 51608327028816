import {
  CreateShipmentResponse,
  CreateShipmentResponseRaw,
} from "./useCreateShipment.types";

export const transformCreateShipmentResponse = (
  responseData: CreateShipmentResponseRaw
): CreateShipmentResponse => {
  return {
    shipments: responseData?.json?.map(({
      carrier_tracking_no,
      dhl_pickup_confirmation_number,
      id,
      label_url,
      price,
      qrcode_url,
    }) => ({
      id,
      labelUrl: label_url,
      carrierTrackingNo: carrier_tracking_no,
      price,
      QRCodeUrl: qrcode_url,
      DHLPickupConfirmationNumber: dhl_pickup_confirmation_number,
    })) ?? [],
  };
};
