import React, { FC } from "react";

import { ConfirmReturnContextProvider } from "~/context/ConfirmReturnContext/ConfirmReturnContext";
import { CustomerDataContextProvider } from "~/context/CustomerDataContext/CustomerDataContext";
import { FormatContextProvider } from "~/context/FormatContext/FormatContext";
import { LanguageContextProvider } from "~/context/LanguageContext/LanguageContext";
import { LoginStateContextProvider } from "~/context/LoginStateContext/LoginStateContext";
import { MerchantSettingsContextProvider } from "~/context/MerchantSettingsContext/MerchantSettingsContext";
import { ReturnContextProvider } from "~/context/ReturnContext/ReturnContext";
import { ReturnMethodsContextProvider } from "~/context/ReturnMethodsContext/ReturnMethodsContext";
import { ShipmentContextProvider } from "~/context/ShipmentContext/ShipmentContext";
import { ThemeContextProvider } from "~/context/ThemeContext/ThemeContext";
import { useMerchantSettings } from "~/hooks/useMerchantSettings/useMerchantSettings";
import { PayPalProvider } from "../PayPalProvider/PayPalProvider";
import { CartVisibilityContextProvider } from "~/context/CartVisibilityContext/CartVisibilityContext";
import { CollectionsNavigationVisibilityContextProvider } from "~/context/CollectionsNavigationVisibilityContext/CollectionsNavigationVisibilityContext";

export const AppProviders: FC = ({ children }) => {
  const {
    data: {
      viewSettings,
      merchant: { currency },
      locales,
    },
  } = useMerchantSettings();
  const { primaryColor, secondaryColor, logoUrl } = viewSettings;
  const theme = {
    primaryColor,
    secondaryColor,
    logoUrl,
  };
  return (
    <ThemeContextProvider theme={theme}>
      <MerchantSettingsContextProvider>
        <LanguageContextProvider merchantLanguages={locales}>
          <FormatContextProvider merchantCurrency={currency}>
            <LoginStateContextProvider>
              <ReturnContextProvider>
                <ReturnMethodsContextProvider>
                  <CustomerDataContextProvider>
                    <ShipmentContextProvider>
                      <ConfirmReturnContextProvider>
                        <PayPalProvider>
                          <CartVisibilityContextProvider>
                            <CollectionsNavigationVisibilityContextProvider>
                              {children}
                            </CollectionsNavigationVisibilityContextProvider>
                          </CartVisibilityContextProvider>
                        </PayPalProvider>
                      </ConfirmReturnContextProvider>
                    </ShipmentContextProvider>
                  </CustomerDataContextProvider>
                </ReturnMethodsContextProvider>
              </ReturnContextProvider>
            </LoginStateContextProvider>
          </FormatContextProvider>
        </LanguageContextProvider>
      </MerchantSettingsContextProvider>
    </ThemeContextProvider>
  );
};
