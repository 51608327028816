import { flatten, sum, uniq } from "lodash";

import { FetchReturnMethodsVariables } from "~/common/types/api";
import { Order, OrderItem } from "~/common/types/order";
import { getRefundInitiatedReturnItems } from "~/components/ReturnOverview/index.util";
import { getCountryLabel } from "~/util/getCountryLabel";
import { isNotNil } from "~/util/isNotNil";
import { InitiatedReturnItem } from "../useReturnState/useReturnState.types";
import { RefundType, ReturnType, StoreCreditType } from "~/common/types/return";

import {
  ReturnMethodsResponse,
  ReturnMethodsResponseRaw,
} from "./useReturnMethods.types";

export const transformReturnMethodsResponse = (
  responseData: ReturnMethodsResponseRaw
): ReturnMethodsResponse => {
  if (responseData) {
    const returnMethods = responseData.settings?.merchant_return_methods ?? [];
    const ruleToken = responseData.settings?.rule_token;
    const {
      name2,
      name,
      street,
      street_number,
      zip,
      city,
      state,
      country_iso2,
    } = responseData.settings?.return_address ?? {};
    return {
      returnMethods: returnMethods.map(
        ({
          id,
          cost,
          image_url,
          return_method_carrier,
          return_method_name,
          name_map,
          description_map,
          cost_message_map,
        }) => ({
          id,
          cost: parseFloat(cost),
          imageUrl: image_url,
          returnMethodCarrier: return_method_carrier,
          returnMethodName: return_method_name,
          nameMap: name_map,
          descriptionMap: description_map,
          costMessageMap: cost_message_map,
        })
      ),
      ruleToken,
      returnAddress: {
        name,
        name2,
        city,
        state,
        zip: zip,
        countryCode: country_iso2,
        street,
        streetNumber: street_number,
      },
    };
  }
  return {
    returnMethods: [],
    ruleToken: null,
    returnAddress: {},
  };
};

export const getFetchReturnMethodsVariables = (
  order: Order,
  orderItems: OrderItem[],
  returnItems: InitiatedReturnItem[],
  selectedRefundType: RefundType,
  selectedStoreCreditOption: StoreCreditType
): FetchReturnMethodsVariables => {
  return {
    customerCountry:
      getCountryLabel(order.customer.address.countryCode) ||
      order.customer.address.countryCode,
    customerTags: order.customer.tags,
    customerEmail: order.customer.email,
    orderAmount: order.amount,
    orderTags: order.orderTags,
    orderDate: order.shippingDate || order.orderDate,
    paymentMethods: order.paymentMethods,
    numberOfItemsToReturn: returnItems.reduce(
      (sum, returnItem) => sum + returnItem.returnQuantity,
      0
    ),
    returnReasonIds: returnItems.map(({ returnReasonId }) => returnReasonId),
    returnType: getReturnTypes(
      returnItems,
      selectedRefundType,
      selectedStoreCreditOption
    ),
    refundTotalValue: getRefundTotalValue(orderItems, returnItems),
    returnTags: getReturnTags(orderItems, returnItems),
    noOfRegisteredReturns: order.noOfRegisteredReturns,
    orderId: order.id,
  };
};

// Note: Currently when calculating the return amount, we only consider items of return type Refund
// For exchange items, currently what happens: neither the customer nor the merchant has to pay price difference
// This logic will change later with the introduction of store credit & payments feature
export const getRefundTotalValue = (
  orderItems: OrderItem[] = [],
  returnItems: InitiatedReturnItem[]
) => {
  const amounts = getRefundInitiatedReturnItems(returnItems).map(
    ({ orderItemId, returnQuantity = 1 }) => {
      const orderItem = orderItems.find(({ id }) => id === orderItemId) ?? {};
      return (orderItem.price ?? 0) * returnQuantity;
    }
  );
  return sum(amounts);
};

export const getReturnTags = (
  orderItems: OrderItem[] = [],
  returnItems: InitiatedReturnItem[]
): string[] => {
  const tags = returnItems.map(({ orderItemId }) => {
    const orderItem = orderItems.find(({ id }) => id === orderItemId) ?? {};
    return orderItem.categories;
  });
  // TODO: Should we also filter duplicate items?
  return flatten(tags).filter(isNotNil);
};

export const getReturnTypes = (
  returnItems: InitiatedReturnItem[],
  selectedRefundType: RefundType,
  selectedStoreCreditOption: StoreCreditType
): string => {
  return uniq(
    returnItems
      .map(({ returnType }) => returnType)
      .filter(uniq)
      .map((returnType) => {
        if (returnType.valueOf() === ReturnType.Refund) {
          if (selectedRefundType === RefundType.StoreCredit) {
            return selectedStoreCreditOption;
          } else if (selectedRefundType === RefundType.ShopExchange) {
            return selectedRefundType.valueOf();
          }
        }
        return returnType.valueOf();
      })
  ).join(",");
};
