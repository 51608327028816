import {
  ConfirmReturnResponse,
  ConfirmReturnResponseRaw,
} from "./useConfirmReturn.types";

export const transformConfirmReturnResponse = (
  responseData: ConfirmReturnResponseRaw
): ConfirmReturnResponse => {
  if (responseData?.refund) {
    const {
      refund: { customer_return_id, shipment: shipments = [] },
    } = responseData;

    return {
      customerReturnId: customer_return_id,
      shipments: shipments.map(
        ({
          created_at,
          dhl_pickup_confirmation_number,
          id,
          label_url,
          price,
          shipping_company,
          shipping_method,
          status,
          tracking_number,
          updated_at,
        }) => ({
          id,
          price,
          status,
          labelUrl: label_url,
          createdAt: created_at,
          updatedAt: updated_at,
          DHLPickupConfirmationNumber: dhl_pickup_confirmation_number,
          shippingCompany: shipping_company,
          shippingMethod: shipping_method,
          trackingNumber: tracking_number,
        })
      ),
    };
  }
  return {
    customerReturnId: 0,
    shipments: [],
  };
};
