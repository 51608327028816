import { identity } from "lodash";
import React, { createContext, FC, useContext, useMemo } from "react";

import { formatCurrency } from "~/util/format";

export type FormatContextType = {
  formatInMerchantCurrency: (value: number | bigint) => string;
};

export type FormatContextProviderProps = {
  merchantCurrency: string;
};

export const FormatContext = createContext<FormatContextType>({
  formatInMerchantCurrency: identity,
});

export const FormatContextProvider: FC<FormatContextProviderProps> = ({
  children,
  merchantCurrency,
}) => {
  const formatInMerchantCurrency = formatCurrency(merchantCurrency);
  const value = useMemo(
    () => ({
      formatInMerchantCurrency,
    }),
    [formatInMerchantCurrency]
  );
  return (
    <FormatContext.Provider value={value}>{children}</FormatContext.Provider>
  );
};

export const useFormatContext = () => useContext(FormatContext);
