import React, { createContext, FC, useContext } from "react";
import { UseQueryResult } from "react-query";

import { useMerchantSettings } from "~/hooks/useMerchantSettings/useMerchantSettings";
import { MerchantSettingsResponse } from "~/hooks/useMerchantSettings/useMerchantSettings.types";

export type MerchantSettingsContextType = DeepPartial<
  Omit<UseQueryResult, "data">
> & {
  data?: MerchantSettingsResponse;
};

export const MerchantSettingsContext =
  createContext<MerchantSettingsContextType>({});

export const MerchantSettingsContextProvider: FC = ({ children }) => {
  const value = useMerchantSettings();
  return (
    <MerchantSettingsContext.Provider value={value}>
      {children}
    </MerchantSettingsContext.Provider>
  );
};

export const useMerchantSettingsContext = () =>
  useContext(MerchantSettingsContext);
