import React, { createContext, FC, useContext } from "react";

import { CustomerName } from "~/common/types/customer";
import { Address } from "~/common/types/return";
import { useUpdateCustomerData } from "~/hooks/useUpdateCustomerData/useUpdateCustomerData";
import { emptyCallback } from "~/util/emptyCallback";

export type CustomerDataContextType = {
  customerEmail: string;
  setCustomerEmail: (customerEmail: string) => void;
  customerName: CustomerName;
  setCustomerName: (customerName: CustomerName) => void;
  customerAddress: Partial<Address>;
  setCustomerAddress: (customerAddress: Partial<Address>) => void;
};

export const CustomerDataContext = createContext<CustomerDataContextType>({
  customerEmail: "",
  setCustomerEmail: emptyCallback,
  customerName: {
    firstName: "",
    lastName: "",
  },
  setCustomerName: emptyCallback,
  customerAddress: {},
  setCustomerAddress: emptyCallback,
});

export const CustomerDataContextProvider: FC = ({ children }) => {
  const value = useUpdateCustomerData();
  return (
    <CustomerDataContext.Provider value={value}>
      {children}
    </CustomerDataContext.Provider>
  );
};

export const useCustomerDataContext = () => useContext(CustomerDataContext);
