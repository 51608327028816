import { useMemo } from "react";
import { useMutation } from "react-query";

import { confirmReturn } from "~/api";

import { transformConfirmReturnResponse } from "./useConfirmReturn.util";

export const useConfirmReturn = () => {
  const {
    mutate,
    data: response,
    isError,
    isLoading,
  } = useMutation("confirmReturnMutation", confirmReturn);
  const data = useMemo(
    () => transformConfirmReturnResponse(response),
    [response]
  );
  return {
    confirmReturn: mutate,
    data,
    isError,
    isLoading,
  };
};
