import React, { FC } from "react";
import {
  PayPalScriptProvider,
  ScriptProviderProps,
} from "@paypal/react-paypal-js";
import { isNil } from "lodash";

import { useMerchantSettings } from "~/hooks/useMerchantSettings/useMerchantSettings";
import { useLanguageContext } from "~/context/LanguageContext/LanguageContext";
import { LocaleCodes } from "~/common/types/common";

import { getPayPalClientIdFromRootDocument } from "./PayPalProvider.util";

export const getPayPalScriptProviderOptions = (
  language: LocaleCodes,
  clientId: string,
  merchantCurrency: string
): ScriptProviderProps["options"] => {
  const localeMap = {
    en: "en_US",
    de: "de_DE",
    fr: "fr_FR",
  };

  const locale = localeMap[language];

  return {
    "client-id": clientId,
    currency: merchantCurrency,
    locale,
    intent: "authorize",
    components: "buttons",
  };
};

export const PayPalProvider: FC = ({ children }) => {
  const { language } = useLanguageContext();

  const PAYPAL_CLIENT_ID = getPayPalClientIdFromRootDocument();

  const {
    data: {
      merchant: { currency },
    },
  } = useMerchantSettings();

  // Only attempt to render the PayPalScriptProvider
  // If the PayPal client ID exists (which means this merchant has set-up PayPal integration)
  if (isNil(PAYPAL_CLIENT_ID)) {
    return <>{children}</>;
  }

  const options = getPayPalScriptProviderOptions(
    language,
    PAYPAL_CLIENT_ID,
    currency
  );

  return (
    <PayPalScriptProvider options={options}>{children}</PayPalScriptProvider>
  );
};
