import React, { createContext, FC, useContext } from "react";
import { UseMutateFunction } from "react-query";

import { FetchReturnMethodsVariables } from "~/common/types/api";
import { useReturnMethods } from "~/hooks/useReturnMethods/useReturnMethods";
import {
  ReturnMethodsResponse,
  ReturnMethodsResponseRaw,
} from "~/hooks/useReturnMethods/useReturnMethods.types";
import { emptyCallback } from "~/util/emptyCallback";

export type ReturnMethodsContextType = {
  fetchReturnMethods: UseMutateFunction<
    ReturnMethodsResponseRaw,
    Error,
    FetchReturnMethodsVariables
  >;
  isError: boolean;
  isLoading: boolean;
  data: ReturnMethodsResponse;
};

export const ReturnMethodsContext = createContext<ReturnMethodsContextType>({
  fetchReturnMethods: emptyCallback,
  isError: false,
  isLoading: false,
  data: null,
});

export const ReturnMethodsContextProvider: FC = ({ children }) => {
  const value = useReturnMethods();
  return (
    <ReturnMethodsContext.Provider value={value}>
      {children}
    </ReturnMethodsContext.Provider>
  );
};

export const useReturnMethodsContext = () => useContext(ReturnMethodsContext);
