import React, { useContext, createContext, FC } from "react";

export type ThemeContextType = {
  logoUrl?: string;
  primaryColor?: string;
  secondaryColor?: string;
};

export const ThemeContext = createContext<ThemeContextType>({});

export type ThemeContextProviderProps = {
  theme: ThemeContextType;
};

export const ThemeContextProvider: FC<ThemeContextProviderProps> = ({
  theme,
  children,
}) => {
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
