export enum Path {
  Login = "/",
  SelectItems = "/items",
  ReturnType = "/return-type",
  ExchangeVariant = "/exchange-variant",
  CustomReturn = "/custom-type",
  SelectQuantity = "/quantity",
  ReturnReason = "/reason",
  ReturnMethod = "/method",
  MoreDetails = "/more-details",
  ReturnOverview = "/overview",
  // RefundInfo = "/refund-info",
  RefundType = "/refund-type",
  ReturnConfirmation = "/confirmation",
  Payment = "/payment",
  Error = "/error",
  CollectionsList = "/collections",
  CollectionDetails = "/collection",
  ProductDetailsShop = "/product",
}

// Note: If the order ID has a hash prefix, it gets removed
// As it currently causes issues with React Memory Router
// We can potentially remove orderId and orderItemId from URL in future versions
// If the plan is to continue using memory based routing
export const pathResolvers = {
  Login: "/",
  SelectItems: `/items`,
  ReturnType: `/return-type`,
  ExchangeVariant: `/exchange-variant`,
  CustomReturn: `/custom-type`,
  SelectQuantity: `/quantity`,
  ReturnReason: `/reason`,
  ReturnMethod: `/method`,
  MoreDetails: `/more-details`,
  ReturnOverview: `/overview`,
  // RefundInfo: `/refund-info`,
  RefundType: `/refund-type`,
  ReturnConfirmation: `/confirmation`,
  Payment: "/payment",
  ColletionsList: "/collections",
  CollectionDetails: "/collection",
  ProductDetailsShop: "/product",
};
