import { RefundShippingCostPolicy, RefundType, ReturnType } from "~/common/types/return";
import { InitiatedReturnItem } from "~/hooks/useReturnState/useReturnState.types";

export const getIsIncludeOriginalShippingCostInRefund = (
  refundShippingCostPolicy: RefundShippingCostPolicy,
  orderItemsQuantity: number,
  registeredItemsQuantity: number,
  initiatedItems: InitiatedReturnItem[],
  refundType: RefundType
): boolean => {
  if (refundShippingCostPolicy === RefundShippingCostPolicy.Refund) {
    return true;
  }
  if (refundShippingCostPolicy === RefundShippingCostPolicy.NoRefund) {
    return false;
  }

  // Check if any ineligible items exist
  const hasIneligibleItems = initiatedItems.some(item =>
      [ReturnType.Refund, ReturnType.Exchange].includes(item.returnType) && refundType === RefundType.ShopExchange
  );

  // If ineligible items exist, no refund on shipping
  if (hasIneligibleItems) {
    return false;
  }
  const initiatedItemsQuantity = initiatedItems.reduce(
    (acc, curr) => acc + curr.returnQuantity,
    0
  );
  return (
    initiatedItemsQuantity + registeredItemsQuantity === orderItemsQuantity
  );
};

export const getRefundInitiatedReturnItems = (
  initiatedItems: InitiatedReturnItem[]
) =>
  initiatedItems.filter(({ returnType }) => returnType === ReturnType.Refund);
