import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";

import i18next, { loadStaticTranslations } from "~/common/i18n";
import { LocaleCode, LocaleCodes } from "~/common/types/common";
import { setFormattingLocale } from "~/util/format";

export type UseLanguageProps = {
  merchantLanguages: LocaleCodes[];
};

export const useLanguage = ({ merchantLanguages }: UseLanguageProps) => {
  const [language, setLanguage] = useState<LocaleCodes>(LocaleCode.en);

  const changeI18nextLanguage = (updatedLanguage: LocaleCodes) => {
    i18next.changeLanguage(updatedLanguage);
  };

  useEffect(() => {
    if (isEmpty(merchantLanguages)) return;

    loadStaticTranslations(merchantLanguages);

    const i18nextLanguage = i18next.languages?.[0] as LocaleCodes;
    if (i18nextLanguage) {
      if (merchantLanguages.includes(i18nextLanguage)) {
        changeLanguage(i18nextLanguage);
      } else {
        changeI18nextLanguage(LocaleCode.en as LocaleCodes);
      }
    }
  }, [merchantLanguages]);

  const changeDropdownLanguage = (updatedLanguage: LocaleCodes) => {
    setLanguage(updatedLanguage);
    setFormattingLocale(updatedLanguage);
  };

  const changeLanguage = useCallback((updatedLanguage: LocaleCodes) => {
    changeI18nextLanguage(updatedLanguage);
    changeDropdownLanguage(updatedLanguage);
  }, []);

  const alignI18nextAndDropdownLanguage = useCallback(
    (i18nextLanguage: LocaleCodes) => {
      if (merchantLanguages.includes(i18nextLanguage)) {
        changeDropdownLanguage(i18nextLanguage);
      } else {
        changeI18nextLanguage(LocaleCode.en as LocaleCodes);
      }
    },
    [merchantLanguages]
  );

  useEffect(() => {
    const i18nextLanguage = i18next.languages?.[0] as LocaleCodes;
    if (!i18nextLanguage) return;
    alignI18nextAndDropdownLanguage(i18nextLanguage);
  }, [merchantLanguages]);

  const i18nextOnInitialized = useCallback(() => {
    if (isEmpty(merchantLanguages)) return;
    const i18nextLanguage = i18next.languages[0] as LocaleCodes;
    alignI18nextAndDropdownLanguage(i18nextLanguage);
  }, [merchantLanguages]);

  useEffect(() => {
    i18next.on("initialized", i18nextOnInitialized);
    return () => i18next.off("initialized", i18nextOnInitialized);
  }, [i18nextOnInitialized]);

  return {
    language,
    changeLanguage,
  };
};
