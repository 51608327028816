import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import BrowserLanguageDetector from "i18next-browser-languagedetector";

import translationEN from "~/locales/EN.json";
import translationDE from "~/locales/DE.json";
import translationFR from "~/locales/FR.json";
import { LocaleCodes } from "~/common/types/common";
import { isDevelopmentEnvironment } from "~/util/nodeEnv";

// const supportedLanguages: LocaleCodes[] = ["en", "de", "fr"];

i18next
  .use(Backend)
  .use(BrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/{{lng}}/text_block",
    },
    detection: {
      order: ["navigator"],
    },
    ns: ["static", "backend"],
    defaultNS: "static",
    fallbackLng: "en",
    load: "languageOnly",
    debug: isDevelopmentEnvironment,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    supportedLngs: false,
    // supportedLngs: supportedLanguages,
  });

const translationsMap = {
  en: translationEN,
  de: translationDE,
  fr: translationFR,
};

export const loadStaticTranslations = (supportedLanguages: LocaleCodes[]) =>
  supportedLanguages.forEach((language) =>
    loadStaticTranslationsByLocaleCode(language)
  );

export const loadStaticTranslationsByLocaleCode = (locale: LocaleCodes) => {
  const translation = translationsMap[locale];
  if (!translation) return;

  i18next.addResourceBundle(locale, "static", translation);
};

export default i18next;
