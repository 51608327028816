import { FC } from "react";

import { Path } from "~/router/routes/path";

export type SVGIconComponentProps = {
  color?: string;
  className?: string;
  size?: string;
  strokeWidth?: number;
};

export enum LocaleCode {}

const locales =
  "aa, ab, af, ak, sq, am, ar, an, hy, as, av, ae, ay, az, ba, bm, eu, be, bn, bh, bi, bs, br, bg, my, ca, ch, ce, zh, cu, cv, kw, co, cr, cs, da, dv, nl, dz, eo, et, ee, fo, fj, fi, fy, ff, ka, gd, ga, gl, gv, el, gn, gu, ht, ha, he, hz, hi, ho, hr, hu, ig, is, io, ii, iu, ie, ia, id, ik, it, jv, ja, kl, kn, ks, kr, kk, km, ki, rw, ky, kv, kg, ko, kj, ku, lo, la, lv, li, ln, lt, lb, lu, lg, mk, mh, ml, mi, mr, ms, mg, mt, mn, na, nv, nr, nd, ng, ne, nn, nb, no, ny, oc, oj, or, om, os, pa, fa, pi, pl, pt, ps, qu, rm, ro, rn, ru, sg, sa, si, sk, sl, se, sm, sn, sd, so, st, es, sc, sr, ss, su, sw, sv, ty, ta, tt, te, tg, tl, th, bo, ti, to, tn, ts, tk, tr, tw, ug, uk, ur, uz, ve, vi, vo, cy, wa, wo, xh, yi, yo, za, zu";

const initialLocales = "en, de, fr";

const localeArray = [
  ...initialLocales.split(",").map((locale) => locale.trim()),
  ...locales.replace(/\s/g, "").split(","),
];

localeArray.forEach((locale) => {
  LocaleCode[locale] = locale;
});

export type LocaleCodes = keyof typeof LocaleCode;

export type RouteType = {
  path: Path;
  Component: FC<any>;
  exact: boolean;
};
