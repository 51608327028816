import { useState } from "react";

import { CustomerName } from "~/common/types/customer";
import { Address } from "~/common/types/return";

export const useUpdateCustomerData = () => {
  const [customerAddress, setCustomerAddress] = useState<Partial<Address>>({});
  const [customerName, setCustomerName] = useState<CustomerName>({
    firstName: "",
    lastName: "",
  });
  const [customerEmail, setCustomerEmail] = useState<string>("");
  return {
    customerEmail,
    setCustomerEmail,
    customerName,
    setCustomerName,
    customerAddress,
    setCustomerAddress,
  };
};
