// polyfills help supporting older browsers like IE, iOS<9 etc
import "../polyfills";

import importRetry from "../importRetry";

// Rollbar
["production", "staging"].includes(process.env.RAILS_ENV) &&
  importRetry(() => import("../vendor/rollbar"));

// Microsoft Clarity
["production", "staging"].includes(process.env.RAILS_ENV) &&
  importRetry(() => import("../vendor/clarity"));

// Google Analytics
process.env.RAILS_ENV === "production" &&
  importRetry(() => import("../vendor/google/analytics/checkout"));

// Google Fonts
["production", "staging"].includes(process.env.RAILS_ENV) &&
  importRetry(() => import("../vendor/google/fonts/inter"));

["production", "staging"].includes(process.env.RAILS_ENV) &&
  importRetry(() => import("../vendor/google/fonts/source_sans_pro"));

// Cookie consent
["production", "staging"].includes(process.env.RAILS_ENV) &&
  importRetry(() => import("../vendor/cookieconsent"));

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("channels")

// Bootstrap (V5)
import "../checkout_application_v3/styles/custom.scss";

import "../checkout_application_v3";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// css styles
// import '../checkout_application/css/application'
