import { LocaleCode } from "./common";

export type ReturnPolicyRaw = DeepPartial<{
  custom_return_name: string;
  exchange_without_track_quantity: boolean;
  expected_refund: boolean;
  inventory_threshold: number;
  policy_url: string;
  refund_shipping_cost: RefundShippingCostPolicyRaw;
  returns_window: number;
  show_custom_return: boolean;
  store_credit_added_percentage: number;
  custom_missing_optional_translations: boolean;
}>;

export type ReturnReasonRaw = DeepPartial<{
  additional_text: boolean;
  additional_text_required: boolean;
  applicable_categories: string;
  id: number;
  image_upload: boolean;
  image_upload_required: boolean;
  show_for_custom_reasons: boolean;
  show_for_exchange_reasons: boolean;
  show_for_refund: boolean;
  text_title_by_locale: any;
  external_text_by_locale: any;
}>;

export type ReturnAddressRaw = DeepPartial<{
  city: string;
  state: string;
  country_iso2: string;
  name: string;
  name2: string;
  street: string;
  street_number: string;
  zip: string;
}>;

export type ReturnAddressShipmentRaw = {
  company: string;
  last_name: string;
  street: string;
  street_no: string;
  city: string;
  state: string;
  zip_code: string;
  country_iso2: string;
};

export type ReturnLabelAddressRaw = {
  return_address_city: string;
  return_address_state: string;
  return_address_company: string;
  return_address_country_iso2: string;
  return_address_last_name: string;
  return_address_street: string;
  return_address_street_no: string;
  return_address_zip_code: string;
};

export type ReturnPolicy = DeepPartial<{
  customReturnName: string;
  exchangeWithoutTrackQuantity: boolean;
  showExpectedRefund: boolean;
  inventoryThreshold: number;
  policyUrl: string;
  refundShippingCost: RefundShippingCostPolicy;
  returnsWindow: number;
  storeCreditAddedPercentage: number;
  inAppShoppingAddedPercentage: number;
  customMissingOptionalTranslations: boolean;
}>;

export type ReturnReason = DeepPartial<{
  id: number;
  externalText: Record<LocaleCode, string>;
  isImageSupported: boolean;
  isImageRequired: boolean;
  isAdditionalTextRequired: boolean;
  isAdditionalTextSupported: boolean;
  textTitle: Record<LocaleCode, string>;
  visible: Record<ReturnTypeRaw, boolean>;
  applicableCategories: string[];
}>;

export type Address = {
  name: string;
  name2?: string;
  countryCode: string;
  city: string;
  street: string;
  streetNumber: string;
  zip: string;
  street2?: string;
  state?: string;
  company?: string | null;
};

export type ReturnLabelAddress = {
  companyName: string;
  city: string;
  state: string;
  countryCode: string;
  street: string;
  streetNumber: string;
  zip: string;
  name: string;
};

export type ReturnTypeRaw = `${ReturnType}`;

export enum ReturnType {
  Refund = "refund",
  Exchange = "exchange",
  Custom = "custom",
  TradeIn = "trade_in",
  shop_now = "shop_exchange",
}

export type RefundShippingCostPolicyRaw = `${RefundShippingCostPolicy}`;

export enum RefundShippingCostPolicy {
  Refund = "refund",
  NoRefund = "no_refund",
  IfAllItemsReturned = "if_all_items_returned",
}

export enum RefundType {
  OriginalPaymentMethod = "original_payment_method",
  StoreCredit = "store_credit",
  ShopExchange = "shop_exchange",
}

export enum StoreCreditType {
  StoreCreditLater = "store_credit",
  StoreCreditInstant = "instant_store_credit",
}

export enum ShopExchangeRefundType {
  OriginalPaymentMethod = "original_payment_method",
  StoreCredit = "store_credit",
}
