import React, { createContext, FC, useContext, useState } from "react";

import { emptyCallback } from "~/util/emptyCallback";

export type CollectionsNavigationVisibilityContextType = {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isVisible: boolean;
};

export const CollectionsNavigationVisibilityContext =
  createContext<CollectionsNavigationVisibilityContextType>({
    setIsVisible: emptyCallback,
    isVisible: false,
  });

export const CollectionsNavigationVisibilityContextProvider: FC = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <CollectionsNavigationVisibilityContext.Provider
      value={{
        isVisible,
        setIsVisible,
      }}
    >
      {children}
    </CollectionsNavigationVisibilityContext.Provider>
  );
};

export const useCollectionsNavigationVisibilityContext = () =>
  useContext(CollectionsNavigationVisibilityContext);
